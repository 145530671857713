import {useState, useEffect} from "react";
import Cookies from "js-cookie";
import PageLoader from "../modules/shared/components/PageLoader";
import {useLocation} from "react-router-dom";
import {getDocumentTitle} from "../utils/generalHelper";

function authLayout(WrappedComponent) {
  return function AuthLayout(props) {
    const location = useLocation();

    const currentLayout = Cookies.get("currentLayout");
    const [cssLoaded, setCssLoaded] = useState(false);
    const linkExists = document.querySelector("link[href='/assets/css/auth-layout.css']");

    useEffect(() => {
      document.title = `R2F - ${getDocumentTitle(location)}`;

      if (currentLayout !== "auth" || !linkExists) {
        const links = document.querySelectorAll("link");
        for (let link of links) {
          const href = link.getAttribute("href");
          if (href === "/assets/css/authenticated-layout.css") {
            link.remove();
          } else if (href === "/assets/css/guest-layout.css") {
            link.remove();
          }
        }
        const link = document.createElement("link");
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("type", "text/css");
        link.setAttribute("href", "/assets/css/auth-layout.css");
        document.head.appendChild(link);
        link.onload = () => {
          Cookies.set("currentLayout", "auth", {expires: 7, path: ""});
          setCssLoaded(true);
        };
      }
    }, []);

    if (currentLayout !== "auth" || !linkExists) {
      return <PageLoader />;
    }
    return <WrappedComponent {...props} />;
  };
}

export default authLayout;
